import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import Accordion from "../../components/template-partials/global-components/accordion/accordion"
import AboutSubmenu from "../../components/template-partials/about/about-submenu"
import InlineInfocard from "../../components/template-partials/global-components/inline-infocard"
import headingBg from "../../images/1.0_backgroundheader.jpg"
import headingRound from "../../images/about-and-research-nav-image.jpg"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"

import boneScan from "../../images/bonescan.jpg"
import tumorRisk from "../../images/1.2_tumor_riskAsset.jpg"
import ctScan from "../../images/1.2.0_ctscanv1.jpg"
import miBgScan from "../../images/1.2.0_mibgscan.jpg"
import mriScan from "../../images/1.2.0_MRIscan.jpg"
import ultraSound from "../../images/1.2.0_ultrasoundv2.jpg"
import xRay from "../../images/1.2.0_xray.jpg"

import stageOfDisease from "../../images/1.2_stageofdisease.jpg"
import ageRisk from "../../images/1.2_age_riskv2.jpg"
import dnaRisk from "../../images/1.2_dna_riskv2.jpg"
import chromosome from "../../images/1.2_chromosome.jpg"
import geneAmp from "../../images/1.2_geneampv2.jpg"

import physical from "../../images/1.2-physical.jpg";
import imaging from "../../images/1.2-imaging.jpg";

import BiopsyImage from "../../components/template-partials/about/biopsy-image"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from '../../components/variables.module.scss';

const HowIsDiagnosedPage = () => {
  return (
    <Layout>
      <SEO title="How is Neuroblastoma Diagnosed | Tests and Scans" description="When diagnosing neuroblastoma, your child will undergo a series of tests and scans. Learn about each procedure, how it works, and what you and your child can expect." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="How is Neuroblastoma Diagnosed?">
        <h1 className="section__heading section__heading--green h1">How <em>is</em> Neuroblastoma Diagnosed?</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`About Neuroblastoma &amp; Research`}>
              <AboutSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">How is neuroblastoma diagnosed?</h2>
                <p>If your child is thought to have neuroblastoma, the healthcare team will gather information using a series of tests and imaging scans.</p>
                <p>After the tests are complete and the oncologist reviews the results, your child’s neuroblastoma will be assigned to either the <Link rel="glossary" to="/support-and-community/glossary/#non-high-risk-neuroblastoma">non–high-risk</Link> (low- or intermediate-risk) or the <Link rel="glossary" to="/support-and-community/glossary/#high-risk-neuroblastoma">high-risk</Link> group. <Link to="/diagnosis/understanding-treatment/">Learn more about these risk groups and what they mean for your child's treatment.</Link> </p>
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.paleBlue}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Physical examination and laboratory tests</h3>
            <p>The healthcare team will begin with a general physical exam of your child, including full medical history, as well as urine and blood tests.</p>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns small-12 large-4 text-center">
            <FancyImage url={physical} alt="Physical examination and laboratory tests"/>
          </div>
          <div className="columns mt-2">
            <h4 className="h4 tc-viridian jost-med">Physical exam and medical history</h4>
            <p>A physical exam may be one of the first exams performed by your child’s oncologist. This is done to check for general signs of health, as well as any physical signs of neuroblastoma, such as lumps, swelling, or weakness in your child’s arms and legs.</p>
            <p>At this time, you may be asked questions about your child’s symptoms and how long they’ve been experiencing them. You may also be asked about your child’s medical history and if there is a family history of neuroblastoma or other cancers.</p>
          </div>
        </div>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h4 className="h4 tc-viridian jost-med">Laboratory tests</h4>
            <p>Urine and blood tests are done as part of the diagnosis process and are also monitored throughout your child’s treatment.</p>
            <ul className="ul--dotted">
              <li>
                Urine tests look for hormones that are released by neuroblastoma cells
                <ul>
                  <li>Your oncologist may call these hormones <Link rel="glossary" to="/support-and-community/glossary/#catecholamines">catecholamines</Link>, and more specifically <Link rel="glossary" to="/support-and-community/glossary/#vanillylmandelic-acid-or-vma">vanillylmandelic acid (VMA)</Link> and <Link rel="glossary" to="/support-and-community/glossary/#homovanillic-acid-or-hva">homovanillic acid (HVA)</Link></li>
                </ul>
              </li>
              <li>
                Blood tests help the team see how your child’s body is working
                <ul>
                  <li>Some potential tests are <Link rel="glossary" to="/support-and-community/glossary/#complete-blood-count-cbc">CBC (complete blood count</Link> or “counts”), electrolyte levels, and liver and kidney function</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </Section>

      <Section>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Imaging and scans for neuroblastoma</h3>
            <p>The healthcare team will use special machines to create detailed images of your child’s internal organs, bones, soft tissues, and blood vessels. This is often referred to as imaging or scans. The type of scans your child receives will depend on where the oncologist thinks the primary tumor is, and if they think the neuroblastoma has spread (<Link rel="glossary" to="/support-and-community/glossary/#metastasize">metastasized</Link>) to other parts of the body.</p>
            <p><strong>To make a neuroblastoma diagnosis, your child’s oncologist may order some or all of the following scans.</strong></p>
          </div>
          <div className="columns small-12 large-4 text-center">
            <FancyImage url={imaging} alt="Imaging and scans for neuroblastoma"/>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <Accordion className="lg_mt-2 mt-2">
              <Accordion.Item isOpen="true">
                <Accordion.Drawer>X-ray</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-2 small-12 text-center">
                    <img src={xRay} width="76" heigh="auto" alt="X-rays are often taken early in the neuroblastoma diagnosis process"/>
                  </div>
                  <div className="columns large-10">
                    <p><Link rel="glossary" to="/support-and-community/glossary/#x-rays">X-rays</Link> are pictures of specific parts of the inside of the body.</p>
                    <ul className='ul--dotted'>
                      <li>An X-ray may be taken early in the diagnosis process, possibly by your child’s pediatrician, to see if there is a tumor in that part of the body causing any of your child’s symptoms. <Link to="/what-is-neuroblastoma/">Learn more about the symptoms of neuroblastoma</Link> </li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>Ultrasound</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-2 small-12 text-center">
                    <img src={ultraSound} width="76" heigh="auto" alt="Ultrasounds may be used early in the neuroblastoma diagnostic process if the oncologist suspects a tumor in that area"/>
                  </div>
                  <div className="columns large-10">
                    <p><Link rel="glossary" to="/support-and-community/glossary/#ultrasound-or-sonogram">Ultrasounds</Link>, also known as <Link rel="glossary" to="/support-and-community/glossary/#ultrasound-or-sonogram">sonograms</Link>, use sound waves to create a picture of tissues or organs inside the body. </p>
                    <ul className='ul--dotted'>
                      <li>An ultrasound may be done early in the diagnosis process, usually before a <Link rel="glossary" to="/support-and-community/glossary/#ct-scan-or-computerized-tomography-scan-also-known-as-a-cat-scan">CT scan</Link> (computerized tomography scan) or <Link rel="glossary" to="/support-and-community/glossary/#mri-or-magnetic-resonance-imaging-scan">MRI scan</Link> (magnetic resonance imaging scan), if the oncologist suspects a tumor in that area</li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>CT scan</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-2 small-12 text-center">
                    <img src={ctScan} width="76" heigh="auto" alt="CT scans allow the oncologist to see the exact size and location of the neuroblastoma tumor"/>
                  </div>
                  <div className="columns">
                    <p>CT scans (computerized tomography), also known as CAT scans, use a computer to make 3D pictures of the inside of the body to see the exact size and location of the neuroblastoma tumor.</p>
                    <ul className='ul--dotted'>
                      <li>Unlike some other scans that allow oncologists to see only bones or only tissues and organs, a CT scan looks at bones, soft tissue, and blood vessels all at one time</li>
                      <li>Contrast dye may be given to help look for the presence of neuroblastoma. This can be given by mouth or through a vein</li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>MRI scan</Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-2 small-12 text-center">
                    <img src={mriScan} width="76" heigh="auto" alt="MRI scans use magnetic waves to take detailed pictures of inside the body to help locate a tumor"/>
                  </div>
                  <div className="columns">
                    <p>MRI scans (magnetic resonance imaging) use magnetic waves to take a series of detailed pictures of the inside of the body.</p>
                    <ul className='ul--dotted'>
                      <li>An MRI scan takes place in a small, narrow tube. During the scan, the machine usually makes loud noises. Infants and young children are often given medication to make them sleepy (<Link rel="glossary" to="/support-and-community/glossary/#sedation">sedated</Link>) for this scan. Older children are usually given the option to use ear plugs or headphones during the scan so they can listen to music</li>
                      <li>Often, the hospital staff will let you walk your child to the room and help them get settled, but you won’t be allowed to stay in the room with your child during the MRI</li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>MIBG scan </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-2 small-12 text-center">
                    <img src={miBgScan} width="76" heigh="auto" alt="MIBG scans use a dye known as a tracer to help oncologists trace the location of the neuroblastoma in your child’s body."/>
                  </div>
                  <div className="columns">
                    <p><Link rel="glossary" to="/support-and-community/glossary/#mibg-or-meta-iodobenzylguanidine-scans">MIBG scans</Link> (meta-iodobenzylguanidine) use a dye containing small amounts of radioactive iodine (I-123 meta-iodobenzylguanidine). This dye is known as a <Link rel="glossary" to="/support-and-community/glossary/#tracer">tracer</Link> because it helps oncologists trace where the neuroblastoma is inside your child’s body.</p>
                    <ul className='ul--dotted'>
                      <li>The test will be performed by injecting a small amount of the radioactive dye (tracer) through an IV tube</li>
                      <li>Pictures will be taken under a scanner 24, 48, or 72 hours after the tracer is given</li>
                      <li>A special medicine will be given to protect your child’s thyroid gland from the radioactive tracer</li>
                    </ul>
                    <p>Following the MIBG scan, the doctor will review the images for “bright spots” (indicative of cancer cells) and provide a <Link rel="glossary" to="/support-and-community/glossary/#curie-score">curie score</Link>. A curie score is a measure of the extent of neuroblastoma in your child’s body and helps predict the severity of their disease.  </p>
                    <p>MIBG scans may be repeated throughout treatment to evaluate if your child’s neuroblastoma is responding to therapy. </p>
                    <p>You may also hear of MIBG being used as a treatment for neuroblastoma. In this case, the tracer uses a different type of radioactive iodine (Iodine 131). <Link to="/relapsed-refractory-neuroblastoma/">Read more about MIBG therapy as a treatment for neuroblastoma</Link>.</p>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>Bone scan </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns large-2 small-12 text-center">
                    <img src={boneScan} width="76" heigh="auto" alt="Bone scans provide a complete scan of your child’s bones to help detect abnormal areas in the bone."/>
                  </div>
                  <div className="columns">
                    <p><Link rel="glossary" to="/support-and-community/glossary/#bone-scan">Bone scans</Link> help oncologists determine if the neuroblastoma has spread (metastasized) to your child’s bones by identifying spots in the bone that are abnormal. A bone scan will provide a picture of all your child’s bones at one time.</p>
                    <ul className='ul--dotted'>
                      <li>Similar to MIBG scans, bone scans use a radioactive dye called an <Link rel="glossary" to="/support-and-community/glossary/#isotope">isotope</Link> that highlights abnormal areas in the bone</li>
                      <li>The oncologists review these images and look for highlighted areas that indicate the presence of neuroblastoma</li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Section>

      <Section style={{backgroundColor: SassVars.honeydew}}>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Biopsies</h3>
            <p><Link rel="glossary" to="/support-and-community/glossary/#biopsy-or-biopsies">Biopsies</Link> are used to confirm: </p>
            <ul className="ul--dotted">
              <li>If the cancer is neuroblastoma</li>
              <li>The characteristics of your child’s tumor</li>
              <li>If the cancer has spread (<Link rel="glossary" to="/support-and-community/glossary/#metastasize">metastasized</Link>) to the bone marrow</li>
            </ul>
            <p>Types of biopsies your child may receive:</p>
            <ul className="ul--dotted">
              <li><Link rel="glossary" to="/support-and-community/glossary/#tumor-biopsy">Tumor biopsy</Link> is a procedure to remove a small sample of the tumor to be examined under a microscope</li>
              <li><Link rel="glossary" to="/support-and-community/glossary/#bone-marrow-aspirate-and-biopsy">Bone marrow aspirate and biopsy</Link> is a procedure done to look at the health of your child’s blood cells, and to search for any neuroblastoma cells that may be present. To do this, a needle will be used to remove liquid bone marrow from inside the hip bone. Oncologists also remove a sample of spongy bone marrow from the same hip bone</li>
            </ul>
            <BiopsyImage className="lg_mt-3 lg_mb-4 mt-3 mb-4" alt="Biopsies are used to confirm a neuroblastoma diagnosis, the type of tumor, and if the cancer has metastasized to the bone marrow."/>
            <p>
              Your child will most likely be asleep (given general <Link rel="glossary" to="/support-and-community/glossary/#anesthesia">anesthesia</Link>) for these procedures.  Ask your child’s oncologist about the type of <Link rel="glossary" to="/support-and-community/glossary/#sedation">sedation</Link> and pain control your child may receive.
            </p>
          </div>
        </div>

        <InlineInfocard className="lg_mt-2 mt-2" text="All of these tests and scans will give your child’s oncologist and healthcare team a lot of helpful information about the type of neuroblastoma your child may have. They will use this information to determine your child’s neuroblastoma risk group and treatment."/>
      </Section>


      <Section>
        <div className="row row--inner align-middle">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">Understanding neuroblastoma risk groups </h3>
            <p>Your child’s oncologist will evaluate the tests and image scan results to determine your child’s <Link rel="glossary" to="/support-and-community/glossary/#risk-group-or-risk-level">risk group</Link>. This “risk” refers to the risk of <Link rel="glossary" to="/support-and-community/glossary/#relapse">relapse</Link> (the risk of the neuroblastoma returning after treatment).</p>
            <p>Neuroblastoma is classified as low-, intermediate-, or high-risk. The treatment your child will receive depends on their risk group. For example, a child with <Link rel="glossary" to="/support-and-community/glossary/#high-risk-neuroblastoma">high-risk neuroblastoma</Link> may receive more intensive treatment because they have a higher risk of relapse.</p>
          </div>
        </div>
        <div className="row row--inner align-middle lg_mt-2 mt-2">
          <div className="columns">
            <h3 className="h4 tc-viridian jost-med">Key risk factors </h3>
          </div>
        </div>
        <div className="row row--inner align-center">
          <div className="columns small-11 large-4">
            <div className="card" style={{borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad"><strong className="tc-midGrey">Stage of disease</strong></p>
                </div>
                <div className="columns small-4">
                  <img src={stageOfDisease} width="100%" height="auto" alt="stage of disease" />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">Describes how much neuroblastoma is in the body and whether it has spread (<Link rel="glossary" to="/support-and-community/glossary/#metastasize">metastasized</Link>) from its original site </p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card" style={{borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad"><strong className="tc-midGrey">Age</strong></p>
                </div>
                <div className="columns small-4">
                  <img src={ageRisk} width="100%" height="auto" alt="age" />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">Many children younger than 18 months have <Link rel="glossary" to="/support-and-community/glossary/#non-high-risk-neuroblastoma">non–high-risk neuroblastoma</Link></p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card" style={{borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad"><strong className="tc-midGrey">Tumor characteristics</strong> (<Link to="/support-and-community/glossary/#histology" rel="glossary">histology</Link>)</p>
                </div>
                <div className="columns small-4">
                  <img src={tumorRisk} width="100%" height="auto" alt="Tumor characteristics" />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">Neuroblastoma cells are studied under a microscope to determine if the cells are favorable or unfavorable</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 align-center">
          <div className="columns small-11 large-4">
            <div className="card" style={{borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad"><strong className="tc-midGrey">MYCN gene amplification</strong></p>
                </div>
                <div className="columns small-4">
                  <img src={geneAmp} width="100%" height="auto" alt="MYCN gene amplification" />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">MYCN is an <Link rel="glossary" to="/support-and-community/glossary/#oncogene">oncogene</Link>, a gene that helps control cell growth. Neuroblastoma tumors that have too many copies of MYCN tend to grow faster and may be harder to treat. Oncologists commonly call this <Link rel="glossary" to="/support-and-community/glossary/#mycn-amplification">MYCN-amplified</Link></p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card" style={{borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad"><strong className="tc-midGrey">DNA index of tumor cell</strong></p>
                </div>
                <div className="columns small-4">
                  <img src={dnaRisk} width="100%" height="auto" alt="DNA index of tumor cell" />
                </div>
              </div>
              <div className="row lg_mt-2 mt-1">
                <div className="columns">
                  <p className="no-pad">The amount of DNA in each cell. <Link rel="glossary" to="/support-and-community/glossary/#dna-index">DNA index</Link> tends to be a useful prognostic factor for children younger than 2 years of age</p>
                </div>
              </div>
            </div>
          </div>
          <div className="columns small-11 large-4">
            <div className="card" style={{borderColor: SassVars.viridian }}>
              <div className="row align-middle">
                <div className="columns large-8">
                  <p className="no-pad"><strong className="tc-midGrey">Chromosome changes</strong></p>
                </div>
                <div className="columns small-4">
                  <img src={chromosome} width="100%" height="auto" alt="Chromosome changes" />
                </div>
              </div>
              <div className="row lg_mt-1">
                <div className="columns">
                  <p className="no-pad">Certain changes in the chromosomes of neuroblastoma tumor cells can help determine a risk  classification</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-2 mt-2">
          <div className="columns">
            <p><strong>At first, the risk groups for neuroblastoma can be complicated and may be hard to understand. If you are unsure about your child’s risk group, ask your child’s oncologist to explain it to you. </strong></p>
          </div>
        </div>

      </Section>


      <InteriorPageFooter>

        <UpNextCard topic="Treatment and Clinical Trials" title="Looking ahead to treatment for your child" lead="After a diagnosis, your child may be asked to participate in a clinical trial. Learn more about clinical trials." url="/what-is-neuroblastoma/clinical-trials/" />


      </InteriorPageFooter>

    </Layout>
  )
}

export default HowIsDiagnosedPage
